/** @jsx jsx */
import { jsx, Box, Button } from 'theme-ui';
import { useI18next } from 'gatsby-plugin-react-i18next';

const SubscriptionForm = ({ buttonLabel, style = {}, ...props }) => {
  const { t } = useI18next();

  return (
    <Box sx={{ ...styles.form, ...style }} {...props}>
      <Button
        as={"a"}
        target={"_blank"}
        href={`https://dashboard.zzenz.com/auth/signup`}
      >
        {buttonLabel ?? t("signup")}
      </Button>
    </Box>
  );
};

export default SubscriptionForm;

const styles = {
  form: {
    input: {
      flexGrow: 1,
      p: ['0 20px', null, null, null, '0 25px'],
      minHeight: [60],
      height: 'auto',
      width: 'auto',
    },
  },
};
