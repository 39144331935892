/** @jsx jsx */
import { jsx, Box, Container, Heading, Text } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { rgba } from 'polished';
import Img from 'components/image';
import SubscriptionForm from 'components/subscription-form';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Helmet from 'react-helmet';

const Banner = () => {
  const { t } = useI18next("index");
  const image = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(toFormat: WEBP) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section id="home" sx={styles.section}>
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.bannerContent}>
            <Heading as="h1">
              {t("banner.header.part1")}
              <mark>{t("banner.header.part2")}</mark>.
            </Heading>
            <Text as="p">
              {t("banner.description")}
            </Text>
            <SubscriptionForm sx={styles.subscriptionForm} />
          </Box>
          <Box as="figure" sx={styles.bannerImage}>
            <Box sx={styles.logoBg} />
            <Helmet
              link={[{
                rel: "preload",
                fetchpriority: "high",
                as: "image",
                href: `https://www.zzenz.com${image.illustration.childImageSharp.fluid.src}`,
                type: "image/webp"
              }]}
            />
            <Img
              src={image.illustration.childImageSharp.fluid}
              alt={t("banner.illustration")}
              fetchpriority={"high"}
              loading={"eager"}
              sx={styles.logo}
            />
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Banner;

const styles = {
  section: {
    backgroundColor: '#f1f9ff',
    pt: [14, null, null, null, null, 0],
    pb: [6, null, null, 7, 11, 0],
  },
  contentWrapper: {
    gap: ['50px 50px'],
    display: ['block', null, null, null, 'grid'],
    gridTemplateColumns: [null, null, null, null, '1fr 1fr', '0.95fr 1.05fr'],
    alignItems: 'center',
    minHeight: ['auto', null, null, null, '38vh', '100vh'],
    pt: [null, null, null, 8, 0, 9, null],
    '@media only screen and (min-width:1900px)': {
      pt: 10,
    },
  },
  bannerContent: {
    margin: [null, null, null, '0 auto', 0],
    maxWidth: [null, null, null, 600, 'none'],
    // textAlign: [null, null, null, 'center', 'left'],
    h1: {
      fontWeight: 400,
      fontSize: [8, null, null, 10, 40, null, 12, 14],
      lineHeight: [1.26, null, null, 1.5, 1.2, 1.26],
      letterSpacing: [0, null, null, null, '-1.5px'],
      color: 'textSecondary',
      textTransform: "uppercase",
      '@media screen and (min-width: 1441px) and (max-width:1600px)': {
        fontSize: 12,
        lineHeight: 1.5,
      },
      mark: {
        backgroundColor: "transparent",
        color: "#2b9ef8",
        fontWeight: 700
      }
    },
    p: {
      fontSize: [1, null, null, 2, 3],
      lineHeight: [1.5],
      color: 'textSecondary',
      maxWidth: [470],
      m: [null, null, null, '30px auto', 0],
      mt: [3, null, null, null, 5],
      fontWeight: 400,
    },
  },
  subscriptionForm: {
    maxWidth: [null, null, null, 470, 'none'],
    m: [null, null, null, '30px auto', '30px 0 0'],
    mt: [6],
    input: {
      backgroundColor: '#FFFFFF',
      border: '0 none',
      fontFamily: 'body',
      fontSize: [1, null, null, null, 2],
      px: [5],
      boxShadow: '0px 16px 40px rgba(72, 59, 26, 0.08)',
      minHeight: [40, 50, null, null, null, 60],
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      '::placeholder': {
        color: rgba('#02073E', 0.4),
        opacity: 1 /* Firefox */,
      },
    },
    a: {
      fontSize: [0, 1, null, null, 2],
      minHeight: [45],
      backgroundColor: "#7536c3",
      "&:hover": {
        backgroundColor: '#2B9EF8',
      }
    },
  },
  bannerImage: {
    alignItems: 'center',
    position: "relative",
    mt: [9, null, null, null, 0],
    img: {
      m: [null, null, null, '0 auto', 0],
    },
  },
  logo: {
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translate(-50%,-0%)",
  },
  logoBg: {
    background: "linear-gradient( -45deg, #9556e3 50%, #47caff 50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    filter: "blur(40px)",
    borderRadius: "50%",
    height: [250, 450],
    width: [250, 450]
  }
};
