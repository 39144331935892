import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import PremiumFeature from 'sections/premium-feature';
import AppFeature from 'sections/app-feature';
import Dashboard from 'sections/dashboard';
import Pricing from 'sections/pricing';
import Testimonials from 'sections/testimonials';
import MobileApp from 'sections/mobile-app';
import Subscribe from 'sections/subscribe';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

export default function IndexPage({ data: { pageImage } }) {
  const { t, language } = useI18next("index");

  return (
    <Layout>
      <SEO
        title={`Zzenz.com ${t("site.title")}`}
        description={t("site.description")}
        image={pageImage?.childImageSharp?.fluid?.src}
        lang={language}
      />
      <Banner />
      <PremiumFeature />
      <AppFeature />
      <Dashboard />
      <Pricing />
      <MobileApp />
      <Testimonials />
      <Subscribe />
    </Layout>
  );
}

export const query = graphql`
  query HomeQuery($language: String!) {
    pageImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["index", "header", "common"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;