/** @jsx jsx */
import { useState } from 'react';
import { keyframes } from '@emotion/core';
import { jsx, Box, Container, Flex, Text } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import PriceTable from 'components/cards/price-table';
import Switch from 'components/switch';
import { useI18next } from 'gatsby-plugin-react-i18next';

const data = [
  {
    id: 1,
    title: 'pricing.part0.title',
    subtitle: 'pricing.part0.subtitle',
    amount: {
      monthly: 'pricing.part0.amount.monthly',
      annual: 'pricing.part0.amount.annual'
    },
    isRecommended: 'pricing.part0.isRecommended',
    buttonText: 'pricing.part0.buttonText',
    features: [
      {
        id: 'pricing.part0.features.part0.id',
        isAvailable: 'pricing.part0.features.part0.isAvailable',
        title: 'pricing.part0.features.part0.title'
      },
      {
        id: 'pricing.part0.features.part1.id',
        isAvailable: 'pricing.part0.features.part1.isAvailable',
        title: 'pricing.part0.features.part1.title'
      },
      {
        id: 'pricing.part0.features.part2.id',
        isAvailable: 'pricing.part0.features.part2.isAvailable',
        title: 'pricing.part0.features.part2.title'
      },
      {
        id: 'pricing.part0.features.part3.id',
        isAvailable: 'pricing.part0.features.part3.isAvailable',
        title: 'pricing.part0.features.part3.title'
      },
      {
        id: 'pricing.part0.features.part4.id',
        isAvailable: 'pricing.part0.features.part4.isAvailable',
        title: 'pricing.part0.features.part4.title'
      },
      {
        id: 'pricing.part0.features.part5.id',
        isAvailable: 'pricing.part0.features.part5.isAvailable',
        title: 'pricing.part0.features.part5.title'
      },
      {
        id: 'pricing.part0.features.part6.id',
        isAvailable: 'pricing.part0.features.part6.isAvailable',
        title: 'pricing.part0.features.part6.title'
      },
      {
        id: 'pricing.part0.features.part7.id',
        isAvailable: 'pricing.part0.features.part7.isAvailable',
        title: 'pricing.part0.features.part7.title'
      },
      {
        id: 'pricing.part0.features.part8.id',
        isAvailable: 'pricing.part0.features.part8.isAvailable',
        title: 'pricing.part0.features.part8.title'
      },
      {
        id: 'pricing.part0.features.part9.id',
        isAvailable: 'pricing.part0.features.part9.isAvailable',
        title: 'pricing.part0.features.part9.title'
      },
      {
        id: 'pricing.part0.features.part10.id',
        isAvailable: 'pricing.part0.features.part10.isAvailable',
        title: 'pricing.part0.features.part10.title'
      },
      {
        id: 'pricing.part0.features.part11.id',
        isAvailable: 'pricing.part0.features.part11.isAvailable',
        title: 'pricing.part0.features.part11.title'
      },
      {
        id: 'pricing.part0.features.part12.id',
        isAvailable: 'pricing.part0.features.part12.isAvailable',
        title: 'pricing.part0.features.part12.title'
      },
      {
        id: 'pricing.part0.features.part13.id',
        isAvailable: 'pricing.part0.features.part13.isAvailable',
        title: 'pricing.part0.features.part13.title'
      },
      {
        id: 'pricing.part0.features.part14.id',
        isAvailable: 'pricing.part0.features.part14.isAvailable',
        title: 'pricing.part0.features.part14.title'
      }
    ]
  },
  {
    id: 2,
    title: 'pricing.part1.title',
    subtitle: 'pricing.part1.subtitle',
    amount: {
      monthly: 'pricing.part1.amount.monthly',
      annual: 'pricing.part1.amount.annual'
    },
    isRecommended: 'pricing.part1.isRecommended',
    buttonText: 'pricing.part1.buttonText',
    features: [
      {
        id: 'pricing.part1.features.part0.id',
        isAvailable: 'pricing.part1.features.part0.isAvailable',
        title: 'pricing.part1.features.part0.title'
      },
      {
        id: 'pricing.part1.features.part1.id',
        isAvailable: 'pricing.part1.features.part1.isAvailable',
        title: 'pricing.part1.features.part1.title'
      },
      {
        id: 'pricing.part1.features.part2.id',
        isAvailable: 'pricing.part1.features.part2.isAvailable',
        title: 'pricing.part1.features.part2.title'
      },
      {
        id: 'pricing.part1.features.part3.id',
        isAvailable: 'pricing.part1.features.part3.isAvailable',
        title: 'pricing.part1.features.part3.title'
      },
      {
        id: 'pricing.part1.features.part4.id',
        isAvailable: 'pricing.part1.features.part4.isAvailable',
        title: 'pricing.part1.features.part4.title'
      },
      {
        id: 'pricing.part1.features.part5.id',
        isAvailable: 'pricing.part1.features.part5.isAvailable',
        title: 'pricing.part1.features.part5.title'
      },
      {
        id: 'pricing.part1.features.part6.id',
        isAvailable: 'pricing.part1.features.part6.isAvailable',
        title: 'pricing.part1.features.part6.title'
      },
      {
        id: 'pricing.part1.features.part7.id',
        isAvailable: 'pricing.part1.features.part7.isAvailable',
        title: 'pricing.part1.features.part7.title'
      },
      {
        id: 'pricing.part1.features.part8.id',
        isAvailable: 'pricing.part1.features.part8.isAvailable',
        title: 'pricing.part1.features.part8.title'
      },
      {
        id: 'pricing.part1.features.part9.id',
        isAvailable: 'pricing.part1.features.part9.isAvailable',
        title: 'pricing.part1.features.part9.title'
      },
      {
        id: 'pricing.part1.features.part10.id',
        isAvailable: 'pricing.part1.features.part10.isAvailable',
        title: 'pricing.part1.features.part10.title'
      },
      {
        id: 'pricing.part1.features.part11.id',
        isAvailable: 'pricing.part1.features.part11.isAvailable',
        title: 'pricing.part1.features.part11.title'
      },
      {
        id: 'pricing.part1.features.part12.id',
        isAvailable: 'pricing.part1.features.part12.isAvailable',
        title: 'pricing.part1.features.part12.title'
      },
      {
        id: 'pricing.part1.features.part13.id',
        isAvailable: 'pricing.part1.features.part13.isAvailable',
        title: 'pricing.part1.features.part13.title'
      },
      {
        id: 'pricing.part1.features.part14.id',
        isAvailable: 'pricing.part1.features.part14.isAvailable',
        title: 'pricing.part1.features.part14.title'
      }
    ]
  },
  {
    id: 3,
    title: 'pricing.part2.title',
    subtitle: 'pricing.part2.subtitle',
    amount: {
      monthly: 'pricing.part2.amount.monthly',
      annual: 'pricing.part2.amount.annual'
    },
    isRecommended: 'pricing.part2.isRecommended',
    buttonText: 'pricing.part2.buttonText',
    features: [
      {
        id: 'pricing.part2.features.part0.id',
        isAvailable: 'pricing.part2.features.part0.isAvailable',
        title: 'pricing.part2.features.part0.title'
      },
      {
        id: 'pricing.part2.features.part1.id',
        isAvailable: 'pricing.part2.features.part1.isAvailable',
        title: 'pricing.part2.features.part1.title'
      },
      {
        id: 'pricing.part2.features.part2.id',
        isAvailable: 'pricing.part2.features.part2.isAvailable',
        title: 'pricing.part2.features.part2.title'
      },
      {
        id: 'pricing.part2.features.part3.id',
        isAvailable: 'pricing.part2.features.part3.isAvailable',
        title: 'pricing.part2.features.part3.title'
      },
      {
        id: 'pricing.part2.features.part4.id',
        isAvailable: 'pricing.part2.features.part4.isAvailable',
        title: 'pricing.part2.features.part4.title'
      },
      {
        id: 'pricing.part2.features.part5.id',
        isAvailable: 'pricing.part2.features.part5.isAvailable',
        title: 'pricing.part2.features.part5.title'
      },
      {
        id: 'pricing.part2.features.part6.id',
        isAvailable: 'pricing.part2.features.part6.isAvailable',
        title: 'pricing.part2.features.part6.title'
      },
      {
        id: 'pricing.part2.features.part7.id',
        isAvailable: 'pricing.part2.features.part7.isAvailable',
        title: 'pricing.part2.features.part7.title'
      },
      {
        id: 'pricing.part2.features.part8.id',
        isAvailable: 'pricing.part2.features.part8.isAvailable',
        title: 'pricing.part2.features.part8.title'
      },
      {
        id: 'pricing.part2.features.part9.id',
        isAvailable: 'pricing.part2.features.part9.isAvailable',
        title: 'pricing.part2.features.part9.title'
      },
      {
        id: 'pricing.part2.features.part10.id',
        isAvailable: 'pricing.part2.features.part10.isAvailable',
        title: 'pricing.part2.features.part10.title'
      },
      {
        id: 'pricing.part2.features.part11.id',
        isAvailable: 'pricing.part2.features.part11.isAvailable',
        title: 'pricing.part2.features.part11.title'
      },
      {
        id: 'pricing.part2.features.part12.id',
        isAvailable: 'pricing.part2.features.part12.isAvailable',
        title: 'pricing.part2.features.part12.title'
      },
      {
        id: 'pricing.part2.features.part13.id',
        isAvailable: 'pricing.part2.features.part13.isAvailable',
        title: 'pricing.part2.features.part13.title'
      },
      {
        id: 'pricing.part2.features.part14.id',
        isAvailable: 'pricing.part2.features.part14.isAvailable',
        title: 'pricing.part2.features.part14.title'
      }
    ]
  },
];

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const { t } = useI18next();

  const handlePlan = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <Box as='section' id='pricing' sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title={t('pricing.title')}
          description={t('pricing.description')}
        />
        <Flex sx={styles.priceSwitcher}>
          <Text as='span'>{t('pricing.monthly')}</Text>
          <Switch isMonthly={isMonthly} handlePlan={handlePlan} />
          <Text as='span'>{t('pricing.annual')}</Text>
        </Flex>
        <Box sx={styles.priceWrapper}>
          {data?.map((price, index) => (
            <PriceTable
              price={price}
              isAnnual={!isMonthly}
              key={`${isMonthly}-${index}`}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeIn2 = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const styles = {
  section: {
    backgroundColor: '#f1f9ff',
    pt: [11],
    pb: [11],
  },
  heading: {
    mb: 3,
    h2: {
      fontSize: [6, null, null, 8],
    },
    p: {
      color: '#858B91',
      fontSize: 3,
      m: ['10px auto', null, null, '0 auto'],
    },
  },
  priceSwitcher: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: [
      '20px auto 20px',
      null,
      null,
      '0 auto 20px',
      null,
      '20px auto 20px',
    ],
    maxWidth: 300,
    p: 2,
    span: {
      color: 'text',
      fontWeight: 500,
      outline: 0,
      p: 0,
      minHeight: 'auto',
    },
  },
  priceWrapper: {
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'center',
    '.priceCard': {
      '.priceHeader': {
        animation: `${fadeIn} 0.8s linear`,
      },
      'ul > li': {
        animation: `${fadeIn2} 0.7s linear`,
      },
      '.priceAmount': {
        animation: `${fadeIn} 0.9s linear`,
      },
      '.priceButton': {
        animation: `${fadeIn2} 0.7s linear`,
      },
    },
  },
};
